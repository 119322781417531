import { Box, type BoxProps, Typography } from '@mui/material';
import StyledImage from './styledImage';
import PageLinkComponent from './page/linkComponent';

export default function QRScanBox( {
	data,
	onClick,
	boxProps = {},
	clickToView = false,
}: {
	data: any,
	onClick?: () => void,
	boxProps?: BoxProps,
	clickToView?: boolean
} ) {
	const { sx, ...rest } = boxProps;
	return (
		<Box
			sx={{
				border      : 1,
				borderRadius: 2,
				borderColor : 'divider',
				p           : 1,
				display     : 'inline-block',
				img         : { width: { xs: 65, sm: 90 }, height: { xs: 65, sm: 90 } },
				...sx || {},
			}}
			{...( clickToView ? {
				component: PageLinkComponent,
				target   : '_blank',
				href     : data,
			} : {} )}
			{...rest}
			onClick={onClick}>
			<StyledImage
				alt='qr-code'
				style={{ borderRadius: 2 }}
				src={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${data}`}
			/>
			<Typography sx={{ color: 'text.secondary', textAlign: 'center' }}>
				Scan
			</Typography>
		</Box>
	);
}
