import { SxProps, Table, TableBody, TableCell, TableRow, useTheme } from '@mui/material';
import PageLink, { PageLinkProps } from './page/link';

export default function EmailButton( {
	spanStyles,
	variant = 'contained',
	emailView,
	...props
}: {
	variant?: 'contained' | 'outlined' | 'text',
	spanStyles?: SxProps,
	emailView?: boolean
} & Omit<PageLinkProps, 'variant'> ) {
	const theme = useTheme();
	const isDarkMode = theme.palette.mode === 'dark';
	
	function getButtonColor() {
		let color;
		if ( variant === 'text' ) {
			if ( isDarkMode ) {
				color = '#ffffff';
			} else if ( emailView ) {
				color = '#000000';
			}
		} else if ( variant === 'outlined' ) {
			color = '#635BFF';
		} else if ( variant === 'contained' ) {
			color = '#ffffff';
		}
		return color;
	}
	
	return (
		<Table style={{ overflow: 'hidden' }}>
			<TableBody>
				<TableRow>
					<TableCell
						align='center'
						sx={{
							'borderRadius': '8px !important',
							'border'      : variant === 'contained' || variant === 'text'
								? '0px !important'
								: variant === 'outlined' ? '1px solid #635BFF !important' : '0px !important',
							'width'     : '100%',
							'textAlign' : 'center',
							'display'   : 'block',
							'background': variant === 'contained' ? '#635BFF' : 'transparent',
							'color'     : '#fff',
							'padding'   : 0,
							'&:hover'   : {
								cursor: 'pointer !important',
								filter: 'brightness(0.9)',
							},
							...spanStyles,
						}}>
						<PageLink
							sx={{
								borderRadius  : '8px !important',
								display       : 'block',
								width         : '100%',
								height        : '100%',
								color         : getButtonColor(),
								textDecoration: 'none',
								padding       : '10px 0px',
							}}
							{...props}
						/>
					</TableCell>
				</TableRow>
			</TableBody>
		</Table>
	);
}
